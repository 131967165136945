<template>
  <div>
    <div class="foot pcblock">
      <div class="Revision">
        <div class="scy-nav">
          <div @click="JumpDetails(item.id)" v-for="(item,index) in Nvalist" :key="index">
            <div :class="item.title=='联系我们'?'ContactRedactive':'ContactRed'">{{item.title}}</div>
          </div>
        </div>
        <div class="Revision_tow">
          <span>委员会地址：中国重庆市南岸区响水路江南明珠8楼</span>
        </div>
      </div>
    </div>
    <div class="phone">
      <div class="phone_nav">
        <div
          @click="JumpDetails(item.id)"
          v-for="(item,index) in Nvalist"
          :key="index"
          class="title"
        >
          <div :class="item.title=='联系我们'?'ContactRedactive':'ContactRed'">{{item.title}}</div>
        </div>
      </div>
      <div class="phone-Revision">
        <span>委员会地址：中国重庆市南岸区响水路江南明珠8楼</span>
      </div>
    </div>
  </div>
</template>
<script>
import { columnlist } from "../api/home.js";
export default {
  data() {
    return {
      Nvalist: []
    };
  },
  created() {
    this.columnLIst();
  },
  methods: {
    guanwang() {
      window.open("https://www.litaifushi.com/");
    },
    contact() {
      if (this.$route.name === "detailscontents") {
        return;
      } else {
        this.$router.push("/detailscontents");
      }
    },
    // 调取导航栏的方法
    async columnLIst() {
      let result = await columnlist();
      this.Nvalist = result.data;
    },
    // 点击目录跳转
    JumpDetails(id) {
      localStorage.setItem("data", id);
      if (id == 10) {
        if (this.$route.path == "/") {
          window.scrollTo({
            top: 0,
            behavior: "smooth" // 平滑滚动效果
          });
          return;
        } else {
          this.$router.push("/");
        }
      } else if (id == 1) {
        if (this.$route.path == "/xiehui/xq.html") {
          window.scrollTo({
            top: 0,
            behavior: "smooth" // 平滑滚动效果
          });
          return;
        } else {
          var query = {
            pages_id: 34, //每条数据的id
            Carryingid: 1 //nav目录的id
          };
          localStorage.setItem("data", JSON.stringify(query));
          this.$router.push({
            path: "/xiehui/xq.html"
          });
        }
      }else if (id == 2 && this.$route.path !== '/gonggaol') {
        this.$router.push("/gonggaol");
      } else if (id == 9) {
        var query = {
          pages_id: 30, //每条数据的id
          Carryingid: 9 //nav目录的id
        };
        localStorage.setItem("data", JSON.stringify(query));
        this.$router.push("/lianxi");
      } else if (id == 3 && this.$route.path !== '/huiyuan') {
        this.$router.push("/huiyuan");
      } else if (id == 4 && this.$route.path !== '/xinwen') {
        this.$router.push("/xinwen");
      } else if (id == 7 && this.$route.path !== '/zhengce') {
        this.$router.push("/zhengce");
      } else if (id == 8 && this.$route.path !== '/yanjiu') {
        this.$router.push("/yanjiu");
      }
    }
  }
};
</script>
<style scoped lang="scss">
.pcblock {
  display: block;
}
.phone {
  display: none;
}
.mt {
  margin-top: 10px;
}
.foot {
  width: 100%;
  height: 120px;
  background: #effafe;
  padding-bottom: 15px;
  margin-top: 30px;
  padding-top: 20px;
  .Revision {
    width: 800px;
    margin: 0 auto;
    .scy-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      clear: both;
      width: 100%;
      height: 50px;
      cursor: pointer;
      margin-bottom: 20px;
      div {
        width: 100%;
        margin-right: 1px;
        font-size: 14px;
        text-align: center;
        line-height: 28px;
        letter-spacing: 3px; //字体间距
        text-align: center;
        &:hover {
          color: #568beb;
        }
      }
      .ContactRed {
        color: #6d7899;
      }
      .ContactRedactive {
        color: red;
      }
    }
  }
}
.Revision_tow {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: #6d7899;
}

// 手机端
@media (max-width: 750px) {
  .pcblock {
    display: none;
  }
  .phone {
    display: block;
    width: 100%;
    background: #76b1f4;
    padding: 0.625rem 0.625rem 0.1rem 0.625rem;
    box-sizing: border-box;
    color: #ffff;
    .phone_nav {
      padding: 0px 1rem;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.75rem;
      .title {
        width: 25%;
        text-align: center;
        margin-bottom: 0.6rem;
      }
    }
    .phone-Revision {
      font-size: 0.75rem;
      text-align: center;
      margin-bottom: 0.25rem;
    }
  }
  .ContactRed {
    color: #ffffff;
  }
  .ContactRedactive {
    color: red;
  }
}
</style>