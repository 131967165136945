<template>
  <div id="app">
      <router-view />
  </div>
</template>
<script>
export default {
};
</script>
<!-- -->
<style lang="scss">
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.min-height {
  margin-top: 15px;
  min-height: 150px;
}
.list {
  width: 100%;
  padding: 2px 0px 2px 10px;
  box-sizing: border-box;
  font-size: 14px;
  .list-item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover .line_radius {
      width: 7px;
      height: 7px;
      border: 1px solid #567fe6 !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
    .timer {
      font-size: 12px;
    }
    .niceimg {
      display: flex;
      align-items: center;

      .line_radius {
        width: 7px;
        height: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        border: 1px solid #fff;
        .rasius {
          width: 5px;
          height: 5px;
          background: #567fe6;
          border-radius: 50%;
        }
      }
    }
  }
}
.mhide-title {
  cursor: pointer;
  display: flex;
  height: 23px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
}
.nince {
  display: flex;
  text-align: center;
  padding: 0px 14px 0px 10px;
  .title {
    margin-left: 5px;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// 背景公共样式
.DetailPages {
  width: 100%;
  min-height: 900px;
  background: url("@/assets/jyb_top_background.jpg") no-repeat top center;
  .center {
    width: 990px;
    margin: 0 auto;
  }
  .Details {
    margin-top: 20px;
    display: flex;
    width: 990px;
    min-height: 400px;
    border: 1px solid #a4a4a4;
    padding: 50px 94px 20px 94px;
    box-sizing: border-box;
  }
}
// 手机端
    .fonnter {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }

</style>