// 对于axios进行二次封装
import axios from "axios";
import {Message  } from 'element-ui';
//在当前模块引入store 
// import store from '@/store'
//1、利用axios对象的方法create,去创建一个axios实例
// 2、request就是axios，只不过稍微配置一下
const requests = axios.create({
    // 配置对象
    // 基础路径,发请求时，路径会出现api
    baseURL:'https://edusk.litaifushi.com/', //公司的地址
    // 代表请求时间
    timeout:4000
})
// 请求拦截器：在请求之前，请求拦截器可以检测到，在发出去之前做一些事
requests.interceptors.request.use((config)=>{
    
    // if(store.state.detail.uuid_token){
    //     //请求一个头字段id
    //     config.headers.userTempId = store.state.detail.uuid_token
    // }
    // //判断需要携带token带给服务器、
    // if(store.state.user.token){
    //     config.headers.token = store.state.user.token
    // }
    return config;
});
// 响应拦截器
requests.interceptors.response.use((res)=>{  
    // 成功的回调函数
    if (res.data.code===200) {
       return res.data;
    }else if (res.data.code===401) {
        //表述用户需要登陆
        Message({
            message: '请先登录',
            type: 'error'
          });
    }else{
        // 错误提示
        Message({
            message:res.data.msg,
            type: 'error'
          });
    }
    },(error)=>{
        // 失败的回调函数
        return Promise.reject(new Error('faile'));
})
// 暴露
export default requests