<template>
  <div>
    <div class="head">
      <div class="icon" @click="home">
        <div class="head—title">重庆市教育装备协会校服专业委员会</div>
      </div>
    </div>
    <div class="headphone">
      <div class="headphone_icon" @click="home">
        
          <img src="@/assets/36.png" alt="" class="img">
          <!-- <div>重庆市教育装备协会校服专业委员会</div> -->
      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  props: {
    msg: String
  },

  methods: {
    home() {
      if (this.$route.name === "home") {
        return;
      } else {
        this.$router.push("/");
      }
    }
  }
};
</script>
<style scoped lang="scss">
.headphone {
  display: none;
}
.head {
  display: flex;
  padding: 30px 0px 0px 0px;
  cursor: pointer;
 .head—title{
  color: #ffff;
  font-size: 60px;
  margin: 20px 0px 40px 0px ;
}
}
// 手机端
@media (max-width: 750px) {
  .head {
    display: none;
  }
  .headphone {
    display: block;
    .headphone_icon {
      width: 100%;
      margin: 0 auto;
      padding-top: 1.25rem;
      .img{
        width: 100%;
        height: 100%;
      }
      // margin: 0 auto;
      // color: #ffffff;
      
      // // -webkit-text-stroke:1px ;
      // //  -webkit-text-stroke-color: #296ee4;
      // font-size: 1.35rem;
      // padding-top: 1.5rem;
      // padding-bottom: 1rem;
      // text-align: center;
      //  letter-spacing: .05rem; //字体间距
      //  font-family: Arial, Helvetica, sans-serif;
      //  font-weight: bold;
    
    }
  }
}
</style>
