import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    
  },
  {
    path: '/index.html',
    name: 'home',
    component: HomeView,
    
  },
  {
    path: '/xinwen',
    name: 'cataloguexq',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/cataloguexq.vue'),    
  },
  {
    path: '/gonggaol',
    name: 'cataloguexq',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/cataloguexq.vue'),    
  },
  {
    path: '/huiyuan',
    name: 'cataloguexq',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/cataloguexq.vue'),
    
  },
  {
    path: '/yanjiu',
    name: 'cataloguexq',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/cataloguexq.vue'),
    
  },
  {
    path: '/zhengce',
    name: 'cataloguexq',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/cataloguexq.vue'),
    
  },
  // 详情页
  {
    path: '/xinwen/xq.html',
    name: 'detailPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/DetailPages.vue'),
   
  },
  {
    path: '/xiehui',
    name: 'detailPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/DetailPages.vue'),
   
  },
  {
    path: '/xiehui/xq.html',
    name: 'association',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/association.vue'),
   
  },
  {
    path: '/redianjujiao/xq.html',
    name: 'detailPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/DetailPages.vue'),
   
  },
  {
    path: '/xianqing/:id',
    name: 'detailPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/DetailPages.vue'),
   
  },
  // 联系我们
  {
    path: '/lianxi',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/ContactUs.vue'),
   
  },
  // 热点聚焦更多页面
  {
    path: '/morehotspot',
    name: 'morehotspot',
    component: () => import(/* webpackChunkName: "about" */ '../views/subpages/Morehotspots.vue'),
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
