<template>
  <div>
    <div class="home pc">
      <div class="scy-min">
        <!-- 头部 -->
        <Head />
        <!-- 导航部分 -->
        <div class="scy-nav">
          <div @click="JumpDetails(item)" v-for="(item,index) in Nvalist" :key="index">
            <div>{{item.title}}</div>
          </div>
        </div>
        <!-- 轮播图 -->
        <div class="jyb-focus">
          <el-carousel :interval="4000" type="card" height="435px" @change="changecarouse($event)">
            <el-carousel-item
              v-for="(item,index) in bannerLiat"
              :key="item.id"
              class="img_carou"
              :style="getstyle(item.image)"
              @click.native="linkTo(item)"
            >
              <div class="hoverze" v-show="carousedata==index">{{item.title}}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 数据展示 -->
        <div class="scy_bottom">
          <!-- 主推新闻 -->
          <div class="moe-index">
            <div class="moe-index-left">
              <div class="mhide-title">
                <div
                  class="mhide-nince"
                  v-for="(item,index) in title"
                  :key="index"
                  @click="mouseEnter(index,item.id)"
                >
                  <img src="../assets/zzjg_scy_jyb_btbj_03.png" alt />
                  <div class="title">{{item.title}}</div>
                </div>
              </div>
              <div class="min-height">
                <div v-if="listData.length">
                  <div
                    class="list zxlist"
                    v-for="(item,index) in listData"
                    :key="index"
                    @click="tzchange(item)"
                    @mouseleave="mouseLeave"
                    @mouseenter="mouseEnterlistData(index)"
                  >
                    <div class="list-item mr">
                      <div class="niceimg">
                        <div class="line_radius">
                          <div class="rasius"></div>
                        </div>
                        <div>{{item.title}}</div>
                      </div>
                      <div class="timer">{{item.created|formatDateYMT}}</div>
                    </div>
                  </div>
                </div>
                <div v-else class="description">
                  <img src="@/assets/800.png" alt />
                  <div>暂无</div>
                </div>
              </div>
            </div>
            <!-- 热点聚焦 -->
            <div>
              <div class="focus">
                <div style="display:flex">
                  <img src="../assets/zzjg_scy_jyb_btbj_03.png" alt />
                  <div class="title">热点聚焦</div>
                </div>
              </div>
              <div class="focuslist">
                <div
                  class="focuslistimg"
                  v-for="(item,index) in focuslistData"
                  :key="index"
                  @click="hotspot(item)"
                >
                  <img :src="'https://edusk.litaifushi.com/'+ item.image" />
                  <div class="focuslist_title">{{item.title}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 尾部 -->
      <Foot />
    </div>
    <!-- 手机端 -->
    <div class="home phone">
      <!--头部 -->
      <div class="header">
        <Head />
      </div>
      <!-- 导航栏 -->
      <div class="nav">
        <div class="card">
          <div v-for="(item,index) in Nvalist" :key="index" @click="JumpDetails(item)">
            <div class="nav_set">{{item.title}}</div>
          </div>
        </div>
      </div>
      <!-- 轮播图 -->
      <div class="wrapper">
        <!-- <van-swipe
          class="my-swipe"
          indicator-color="white"
          :show-indicators="false"
          ref="swipe"
          v-show="bannerLiat.length"
        >
          <van-swipe-item v-for="(item, index) in bannerLiat" :key="index" >
            <img class="wrapperimg" :src="'https://edusk.litaifushi.com/'+item.image" />
            <div class="hoverze">{{item.title}}</div>
          </van-swipe-item>
        </van-swipe>-->
        <swiper class="mySwiper"  :options="swiperOptions">
          <swiper-slide v-for="(item, index) in bannerLiat" :key="index"  >
            <img class="mySwiperwrapperimg" :src="'https://edusk.litaifushi.com/'+item.image"  @click="linkTo(item)"/>
            <div class="hoverze">{{item.title}}</div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- 列表 -->
      <div class="liebiao">
        <div class="liebiao_nav">
          <div
            v-for="(item,index) in title"
            :key="index"
            class="sett"
            @click="mouseEnter(index,item.id)"
          >
            <img src="../assets/zzjg_scy_jyb_btbj_03.png" alt />
            <div class="title">{{item.title}}</div>
          </div>
        </div>
      </div>
      <!-- 列表end -->
      <!-- 列表内容 -->
      <div class="liebiao_list">
        <div v-if="listData.length">
          <div v-for="(item,index) in listData" :key="index" class="event" @click="tzchange(item)">
            <div class="ine"></div>
            <div class="title">{{item.title}}</div>
            <div class="timer">{{item.created|formatDateYMT2}}</div>
          </div>
        </div>
        <div class="description" v-else>
          <img src="@/assets/800.png" alt />
        </div>
      </div>
      <div class="more" v-if="listData.length>8" @click="changemore">查看更多>></div>
      <!-- 热点聚焦 -->
      <div>
        <div class="rdjj">
          <div class="tub">
            <img src="../assets/zzjg_scy_jyb_btbj_03.png" alt />
            <div class="title">热点聚焦</div>
          </div>
        </div>
        <div class="rdjjlist">
          <div
            class="crdjjlist_img"
            v-for="(item,index) in focuslistData"
            :key="index"
            @click="hotspot(item)"
          >
            <img :src="'https://edusk.litaifushi.com/'+ item.image" />
            <div class="focuslist_title">{{item.title}}</div>
          </div>
        </div>
      </div>
      <Foot />
    </div>
  </div>
</template>
<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import {
  columnlist,
  bannerlist,
  pagesList,
  pagesHhot,
  pagesMain,
  pagesKey
} from "../api/home.js";
export default {
  name: "HomeView",
  components: { Head, Foot, swiper, swiperSlide },
  data() {
    return {
      swiperOptions: {
        // 基本配置
        direction: "horizontal", // 滑动方向，可以是'horizontal'或'vertical'
        loop: true, // 是否循环
        autoplay: {
          delay: 2500, // 自动播放间隔
          disableOnInteraction: false // 用户操作后是否停止自动播放
        },
        // 分页器配置
        pagination: {
          clickable: true // 分页器是否可点击
        },
        // 导航按钮配置
        navigation: {
          nextEl: ".swiper-button-next", // 下一项元素
          prevEl: ".swiper-button-prev" // 上一项元素
        },
        // 滚动条配置
        scrollbar: {
          el: ".swiper-scrollbar" // 滚动条元素
        }
      },
      show: false,
      carousedata: 0,
      Nvalist: [], //导航栏的事件
      bannerLiat: [],
      isActive: false,
      isA: -1,
      title: [
        {
          id: 4,
          title: "咨讯中心"
        }
      ],
      listData: [],
      focuslistData: [],
      Carryingid: null, //携带到详情页的id,
      MainNews: [],
      homeid: "" //首页的id
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.swipe.resize();
        });
      }
    }
  },
  created() {
    this.columnLIst();
    this.bannerlist();
    this.pagesList(4);
    this.pagesHhot();
    this.pagesMain();
    this.Carryingid = 1;
    window.scrollTo(0, 0);
  },
  methods: {
    getstyle(img) {
      return {
        background: "url(" + "https://edusk.litaifushi.com/" + img + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: " no-repeat"
      };
    },
    mouseLeave() {
      this.isA = null;
    },
    // 咨询中心点击目录跳转
    mouseEnter(index, id) {
      localStorage.setItem("data", id);
      this.$router.push("/xinwen");
    },
    // 点击导航栏跳转
    JumpDetails(item) {
      localStorage.setItem("data", item.id);
      if (item.id == 10) {
        if (this.$route.name == "home") {
          return;
        } else {
          this.$router.push("/");
        }
      } else if (item.id == 1) {
        var query = {
          pages_id: 34, //每条数据的id
          Carryingid: 1 //nav目录的id
        };
        localStorage.setItem("dataliat", JSON.stringify(query));
        this.$router.push({
          path: "/xiehui"
        });
      } else if (item.id == 2) {
        this.$router.push("/gonggaol");
      } else if (item.id == 9) {
        var query = {
          pages_id: 30, //每条数据的id
          Carryingid: 9 //nav目录的id
        };
        localStorage.setItem("data", JSON.stringify(query));
        this.$router.push("/lianxi");
      } else if (item.id == 3) {
        this.$router.push("/huiyuan");
      } else if (item.id == 4) {
        this.$router.push("/xinwen");
      } else if (item.id == 7) {
        this.$router.push("/zhengce");
      } else if (item.id == 8) {
        this.$router.push("/yanjiu");
      }
    },
    mouseEnterlistData(index) {
      this.isA = index;
    },
    //
    changecarouse(index) {
      this.carousedata = index;
    },
    linkTo(index) {
      var query = {
        pages_id: index.id, //每条数据的id
        Carryingid: 12 //nav目录的id
      };
      localStorage.setItem("dataliat", JSON.stringify(query));
      this.$router.push({
        path: `/xianqing/id=${index.id}`
      });

      // this.$router.push({
      //   path: "/detailPages",
      //   query: {
      //     pages_id: index.id, //每条数据的id
      //     Carryingid: 12
      //   }
      // });
    },
    // 查询导航栏的数据
    async columnLIst() {
      let result = await columnlist();
      this.Nvalist = result.data;
      this.Nvalist.forEach(item => {
        if (item.title == "首页") {
          return (this.homeid = item.id);
        }
      });
      this.pageskey(this.homeid);
    },
    // 轮播图
    async bannerlist() {
      let result = await bannerlist();
      this.bannerLiat = result.data;
    },
    // 内容列表
    async pagesList(column_id) {
      let data = {
        column_id: column_id,
        page: 1,
        count: 8
      };
      let result = await pagesList(data);
      this.listData = result.data.data;
    },
    // 热点聚焦
    async pagesHhot() {
      let result = await pagesHhot();
      this.focuslistData = result.data;
    },
    // 咨询中心跳转详情
    tzchange(item) {
      var query = {
        pages_id: item.id, //每条数据的id
        Carryingid: 4 //nav目录的id
      };
      localStorage.setItem("dataliat", JSON.stringify(query));
      this.$router.push({
        path:`/xianqing/id=${item.id}`
      });
    },
    // 热点聚焦跳转详情
    hotspot(item) {
      var query = {
        pages_id: item.id, //每条数据的id
        Carryingid: 11 //nav目录的id
      };
      localStorage.setItem("dataliat", JSON.stringify(query));
      this.$router.push({
        path: "/redianjujiao/xq.html"
      });
    },
    // 查询主推新闻数据
    async pagesMain() {
      let result = await pagesMain();
      this.MainNews = result.data;
    },
    // 查看更多
    changemore() {
      this.$router.push({
        path: "/cataloguexq",
        query: {
          key: this.Carryingid
        }
      });
    },
    // 根据导航栏的信息查询页面关键字 async
    async pageskey(id) {
      let result = await pagesKey({ column_id: id });
      // 修改页面title
      document.title = result.data.ex_title;
      // 修改页面的关键词
      let newKeywords = document.createElement("meta");
      newKeywords.setAttribute("name", "keywords");
      newKeywords.setAttribute("content", result.data.keywords);
      document.head.appendChild(newKeywords);
      // 修改页面描述
      let Description = document.createElement("meta");
      Description.setAttribute("name", "Description");
      Description.setAttribute("content", result.data.description);
      document.head.appendChild(Description);
    }
  },
  filters: {
    formatDateYMT(value) {
      let arr = value.split(" ");
      let time = arr[0];
      return time;
    },
    formatDateYMT2(value) {
      let arr = value.split(" ");
      let time = arr[0].split("-");
      return time[1] + "-" + time[2];
    }
  }
};
</script>
<style scoped lang="scss">
.pc {
  display: block;
}
.phone {
  display: none;
}
::v-deep .el-carousel {
  .el-carousel__item--card.is-active {
    width: 700px !important;
    left: -100px;
  }
}
.matop {
  margin-bottom: 30px;
}
.mr {
  margin: 2px;
}
.home {
  width: 100%;
  height: 100%;
  background: url("@/assets/index_scy_jyb_banner.jpg") no-repeat top center;
}
.scy-min {
  width: 990px;
  margin: 0 auto;
  .scy-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    clear: both;
    width: 990px;
    height: 50px;
    cursor: pointer;
    margin-bottom: 30px;
    div {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #568beb;
      margin-right: 1px;
      font-family: "微软雅黑";
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      letter-spacing: 3px; //字体间距
      text-align: center;
      &:hover {
        background: #ffffff;
        color: #568beb;
      }
    }
  }
  .jyb-focus {
    clear: both;
    position: relative;
    margin: 20px auto 0;
    width: 990px;
    height: 435px;
    .img_carou {
      .hoverze {
        position: absolute;
        bottom: 2px;
        width: 100%;
        height: 33px;
        line-height: 33px;
        background: rgba(0, 0, 0, 0.2);
        color: #ffffff;
        padding: 0px 5px;
      }
    }
  }
  .scy_bottom {
    position: relative;
    width: 990px;
    padding-top: 30px;
    .moe-headline {
      margin-bottom: 30px;
      color: #567fe6;
      line-height: 1.5;
      text-align: center;
      h3 {
        font-size: 22px;
        padding: 5px 0;
      }
      .fuzhu {
        cursor: pointer;
      }
    }
    .moe-index {
      margin-bottom: 20px;
      .moe-index-left {
        text-align: left;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 30px;
        .mhide-nince {
          width: 110px;
          color: #567fe6;
          display: flex;
          border-bottom: 2px solid #567fe6;
        }
      }
    }
  }
}
.zxlist {
  &:hover {
    color: #568beb;
  }
}
.activecolor {
  color: #567fe6;
}
.focus {
  width: 100%;
  color: #567fe6;
  height: 23px;
  border-bottom: 1px solid #e5e5e5;
  img {
    padding-left: 10px;
  }

  div {
    width: 110px;
    height: 23px;
    border-bottom: 2px solid #567fe6;
  }
}
.focuslist {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .focuslistimg {
    cursor: pointer;
    margin: 0px 20px 20px 10px;
    img {
      width: 210px;
      height: 110px;
      background-size: 100%;
    }
    .focuslist_title {
      margin-top: 5px;
      width: 205px;
      text-align: center;
      font-size: 13px;
    }
  }
}
.active {
  color: #567fe6;
  border-bottom: 2px solid #567fe6;
  .title {
    margin-left: 5px;
  }
}
.description {
  text-align: center;
  font-size: 12px;
  color: #bac1cd;
  letter-spacing: 3px; //字体间距
}
// 手机端
@media (max-width: 750px) {
  .pc {
    display: none;
  }
  .more {
    width: 100%;
    background: #e5e5e5;
    text-align: center;
    margin: 0.625rem 0px;
  }
  .phone {
    display: block;
    width: 100%;
    height: 100%;
    background: url("@/assets/m_top_bg.png") no-repeat top center;
    background-size: 100%;
    min-height: 500px;
    .header {
      width: 100%;
    }
    .nav {
      margin-top: 0.4rem;
      width: 100%;
      height: 2.375rem;
      overflow-y: scroll;
      line-height: 2.375rem;
      font-size: 0.875rem;
    }
    .nav::-webkit-scrollbar {
      display: none;
    }
    .card {
      display: flex;
      flex-wrap: nowrap;
    }
    .nav_set {
      padding: 0rem 0.35rem;
      width: 4.5rem;
      text-align: center;
      background: linear-gradient(to bottom, #45b1f9, #4683eb);
      color: #ffffff;
      font-family: "微软雅黑";
    }
    .lunbotu {
      margin-bottom: 1.5rem;
      clear: both;
      position: relative;
      width: 100%;
      height: 230px;
      .img_carou {
        width: 100%;
        height: 100%;
      }
    }
    .tjxw {
      padding: 0px 0.8rem;

      text-align: center;
      color: #567fe6;
      line-height: 1.5;
    }
    .liebiao {
      margin-top: 2rem;
      .liebiao_nav {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        text-align: center;
        .sett {
          margin-left: 2%;
          display: flex;
          width: 25%;
          padding-bottom: 2px;
          border-bottom: 2px solid #567fe6;
        }
      }
    }
    .liebiao_list {
      margin-top: 1.1rem;
      min-height: 5.5rem;

      .event {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        .ine {
          width: 5px;
          height: 5px;
          background: #567fe6;
        }
        .title {
          width: 76%;
          margin: 0px 6px 0px 3px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
    }
    .rdjj {
      margin-top: 1.25rem;
      width: 100%;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 1rem;

      .tub {
        display: flex;
        padding-bottom: 0.125rem;
        margin-left: 2%;
        width: 25%;
        border-bottom: 2px solid #567fe6;
      }
    }
    .rdjjlist {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 8px;
      box-sizing: border-box;
      .crdjjlist_img {
        width: 49%;
        margin-bottom: 0.625rem;

        img {
          width: 100%;
          height: 100px;
          background-size: 100%;
          image-rendering: -webkit-optimize-contrast; //处理模糊的代码
          background-size: 100%;
        }
        .focuslist_title {
          text-align: center;
          width: 100%;
          height: 2.5rem;
          overflow: hidden; /* 溢出部分进行隐藏 */
          display: -webkit-box;
          -webkit-box-orient: vertical; /* 盒模型元素从顶部向底部垂直排列子元素 */
          -webkit-line-clamp: 2; /* 显示两行 */
          text-overflow: ellipsis;
        }
      }
    }
    .wrapper {
      width: 100%;
      height: 235px;
      position: relative;
      .hoverze {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 33px;
        line-height: 33px;
        background: rgba(0, 0, 0, 0.8);
        color: #ffffff;
        padding: 0px 5px;
        font-size: 0.875rem;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .my-swipe .van-swipe-item {
        font-size: 20px;
        width: 100%;
        height: 235px;
        text-align: center;
        .wrapperimg {
          width: 100%;
          height: 235px;
        }
      }
    }
    .mySwiper {
      width: 100%;
      height: 235px;
      .mySwiperwrapperimg {
        width: 100%;
        height: 235px;
        background-size: 100%;
      }
    }
  }
  .timer {
    width: 18%;
    text-align: right;
  }
  .home {
    width: 100%;
    height: 100%;
  }
}
</style>
