//引入
import axios from "./axios.js"; //路劲自己修改
// 导航栏
export const columnlist = (params) => {
    return axios({
        url: "/home/column/column_list",
        method: "post" ,  
        data: params
    })
}
// 首页的banner
export const bannerlist = (params) => {
    return axios({
        url: "/home/column/banner",
        method: "post" ,  
        data: params
    })
}
// 热点聚焦
export const pagesHhot = (params) => {
    return axios({
        url: "/home/column/pages_hot",
        method: "post" ,  
        data: params
    })
}
// 单页内容列表
export const pagesList = (params) => {
    return axios({
        url: "/home/column/pages_list",
        method: "post" ,  
        data: params
    })
}
// 内容详情 pages_detail
export const pagesDetail = (params) => {
    return axios({
        url: "/home/column/pages_detail",
        method: "post" ,  
        data: params
    })
}
// 主推新闻   edusk.litaifushi.com/home/column/pages_main
export const pagesMain = (params) => {
    return axios({
        url: "/home/column/pages_main",
        method: "post" ,  
        data: params
    })
}
// 导航栏关键字
export const pagesKey = (params) => {
    return axios({
        url: "/home/column/pages_key",
        method: "post" ,  
        data: params
    })
}


                   
